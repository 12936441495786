var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card gutter-b card-stretch shadow-sm"},[_c('div',{staticClass:"card-header border-0 d-flex pb-4 ribbon ribbon-clip ribbon-right"},[_c('div',{staticClass:"ribbon-target p-3",staticStyle:{"top":"12px"}},[_c('span',{staticClass:"ribbon-inner",class:{
            'bg-primary': _vm.phase.phaseStatus.id == 2,
            'bg-warning': _vm.phase.phaseStatus.id == 1,
            'bg-danger': _vm.phase.phaseStatus.id == 3
          }}),_c('span',{staticClass:"text-uppercase font-weight-bolder"},[_vm._v(_vm._s(_vm.labels[_vm.phase.phaseStatus.id]))])]),_c('div',{staticClass:"row w-100 align-items-center"},[_c('div',{staticClass:"d-none d-sm-block ml-5 mr-6"},[_c('img',{staticClass:"w-100 mb-3",staticStyle:{"max-width":"140px"},attrs:{"src":_vm.phase.logo
              ? _vm.phase.logo.uri
              : '/assets/media/bipart/phase-default.png'}})]),_c('div',{},[_c('div',{staticClass:"card-toolbar"},[_c('div',{staticClass:"card-toolbar mb-2"},[(_vm.phase.openDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('GENERALLABEL.DAL')))+" "),_c('b',{staticClass:"font-weight-bolder h5"},[_vm._v(_vm._s(_vm._f("moment")(_vm.phase.openDate,"DD/MM/Y")))])]):_vm._e(),(_vm.phase.closeDate)?_c('span',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm.$t('GENERALLABEL.AL'))+" "),_c('b',{staticClass:"font-weight-bolder h5"},[_vm._v(_vm._s(_vm._f("moment")(_vm.phase.closeDate,"DD/MM/Y")))])]):_vm._e()])]),_c('h3',{staticClass:"text-dark text-hover-primary font-size-h3 font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.phase.title)+" ")])])])]),(this.phase.process2Modules)?_c('div',{staticClass:"card-footer py-0 px-0 align-items-center"},_vm._l((_vm.phase.process2Modules),function(item){return _c('div',{key:item.id,staticClass:"shadow-sm pt-4 pb-3 px-10 py-2 border-bottom mb-2"},[_c('ModuleCard',{attrs:{"module":item,"phase":_vm.phase}})],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }