<template>
<div class="card gutter-b card-stretch shadow-sm">

  <div class="card-header border-0 d-flex pb-4 ribbon ribbon-clip ribbon-right">
    <div class="ribbon-target p-3" style="top: 12px;">
      <span
          class="ribbon-inner"
          v-bind:class="{
            'bg-primary': phase.phaseStatus.id == 2,
            'bg-warning': phase.phaseStatus.id == 1,
            'bg-danger': phase.phaseStatus.id == 3
          }"
      ></span><span class=" text-uppercase font-weight-bolder">{{ labels[phase.phaseStatus.id] }}</span>
    </div>
    <div class="row w-100 align-items-center">
      <div class="d-none d-sm-block ml-5 mr-6">
          <img
          :src="
            phase.logo
              ? phase.logo.uri
              : '/assets/media/bipart/phase-default.png'
          "
          class="w-100 mb-3"
          style="max-width: 140px"
        />
      </div>
      <div class="" >
        <!-- <p class="mt-4" v-html="phase.description"></p> -->
        <div class="card-toolbar">
          <div class="card-toolbar mb-2">
              <!-- <span
                class="label label-circle bg-secondary text-dark
                p-8 text-center h3 font-weight-bolder text-dark-75 mr-3">
                  {{ phase.index + 1 }}
              </span> -->

              <!--
              <span v-if="phase.openDate"
                class="label label-inline label-lg label-light-primary font-weight-bolder"
              >
                {{ phase.openDate | moment("DD/MM/Y") }}</span
              >
              <span  v-if="phase.closeDate"
                class="ml-2 label label-inline label-lg label-light-danger font-weight-bolder"
                >{{ phase.closeDate | moment("DD/MM/Y") }}</span
              >
            </div>
            -->

            <span v-if="phase.openDate"> {{$t('GENERALLABEL.DAL')  | capitalize}} <b class="font-weight-bolder h5">{{ phase.openDate | moment("DD/MM/Y")}}</b> </span>
            <span class="ml-4" v-if="phase.closeDate"> {{$t('GENERALLABEL.AL')}} <b  class="font-weight-bolder h5">{{ phase.closeDate | moment("DD/MM/Y")}}</b></span>
          </div>
        </div>

        <h3 class="text-dark text-hover-primary font-size-h3 font-weight-bolder mb-1">
           {{ phase.title }}
        </h3>

      </div>
    </div>
  </div>
  <div class="card-footer py-0 px-0 align-items-center " v-if="this.phase.process2Modules">
    <div
      v-for="item in phase.process2Modules"
      :key="item.id"
      class="shadow-sm pt-4 pb-3 px-10  py-2 border-bottom  mb-2">
      <ModuleCard :module="item" :phase="phase"></ModuleCard>
    </div>
  </div>
</div>

</template>

<script>
import ModuleCard from "@/view/pages/process/partials/ModuleCard";

export default {
  name: "ProcessPhase",
  components: {
    ModuleCard
  },
  data(){
    return {
      labels: []
    };
  },
  props: ["phase", "index"],
  created() {

    console.log("FASE:", this.phase);

    this.labels[1] = this.$t("PARTIALSPROCESSI.DAATTIVARE");
    this.labels[3] = this.$t("PARTIALSPROCESSI.CONCLUSA");
    this.labels[2] = this.$t("PARTIALSPROCESSI.INCORSO");
  },
  computed: {
    getNrCol() {
      // return this.phase.process2Modules.length > 1 ? 6 : 12;
      let col;
      switch (this.phase.process2Modules.length) {
        case 1: {
          col = 12;
          break;
        }
        // case 2: {
        //   col = 6;
        //   break;
        // }
        // default: {
        //   col = 4;
        //   break;
        // }
        default: {
          col = 6;
          break;
        }

      }
      // console.log('tot: ' + this.phase.process2Modules.length);
      // console.log('col: ' + col);
      return col;
    },


  }
};
</script>

<style scoped></style>
