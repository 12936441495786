var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between mb-3 align-items-center"},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('div',{staticClass:"symbol symbol-50 symbol-light",style:(`width: 50px; height: 50px; background: url(${this.getModuleIcon}) no-repeat center; background-size:cover;`)}),(_vm.phase.phaseStatus.id==1)?_c('a',{attrs:{"href":"#"},on:{"click":_vm.alertNotActive}},[_c('div',{staticClass:"h5 mx-6 text-dark-75 text-hover-primary"},[_vm._v(_vm._s(_vm.module.title))])]):_c('router-link',{attrs:{"to":{
            name: _vm.getModuleCall,
            params: {
              id: _vm.module.id,
              process_id: _vm.module.process.id,
              phase_id: _vm.phase.id
            }
          }}},[_c('div',{staticClass:"h5 mx-6 text-dark-75 text-hover-primary"},[_vm._v(_vm._s(_vm.module.title))])])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"card-toolbar mt-3"},[(_vm.phase.open)?_c('router-link',{attrs:{"to":{
            name: _vm.getModuleCall,
            params: {
              id: _vm.module.id,
              process_id: _vm.module.process.id,
              phase_id: _vm.phase.id
            }
          }}},[_c('div',{staticClass:"d-flex flex-row btn btn-md btn-primary font-weight-bolder text-uppercase rounded align-items-center"},[_c('span',{},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.PARTECIPA")))])])]):_c('router-link',{attrs:{"to":{
            name: _vm.getModuleCall,
            params: {
              id: _vm.module.id,
              process_id: _vm.module.process.id,
              phase_id: _vm.phase.id
            }
          }}},[_c('div',{staticClass:"d-flex flex-row btn btn-md btn-primary font-weight-bolder text-uppercase rounded align-items-center"},[_c('span',{},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.VISUALIZZA")))])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }