<template>
  <div class="d-flex justify-content-between mb-3 align-items-center">
    <div class="d-flex flex-row align-items-center">
      <div class="symbol symbol-50 symbol-light" :style="`width: 50px; height: 50px; background: url(${this.getModuleIcon}) no-repeat center; background-size:cover;`">
<!--        <img :src="getModuleIcon" class="rounded w-100" alt="" />-->
      </div>
<!--      #qui: mettere alert-->
      <a
          v-if="phase.phaseStatus.id==1"
          href="#"
          @click="alertNotActive"
      >
        <div class="h5 mx-6 text-dark-75 text-hover-primary ">{{ module.title }}</div>
      </a>
<!--      / #qui: mettere alert-->
      <router-link
          v-else
            :to="{
            name: getModuleCall,
            params: {
              id: module.id,
              process_id: module.process.id,
              phase_id: phase.id
            }
          }"
        >
      <div class="h5 mx-6 text-dark-75 text-hover-primary ">{{ module.title }}</div>
      </router-link>
    </div>
    <div class="d-flex align-items-center">
      <div class="card-toolbar mt-3">

        <!-- PHASE OPEN -->
        <router-link
            v-if="phase.open"
            :to="{
            name: getModuleCall,
            params: {
              id: module.id,
              process_id: module.process.id,
              phase_id: phase.id
            }
          }"
        >
          <div class="d-flex flex-row btn btn-md btn-primary font-weight-bolder text-uppercase rounded align-items-center">
             <span class="">{{$t("PARTIALSPROCESSI.PARTECIPA")}}</span>
          </div>
        </router-link>

        <!-- PHASE CLOSE  -->
        <router-link
            v-else
            :to="{
            name: getModuleCall,
            params: {
              id: module.id,
              process_id: module.process.id,
              phase_id: phase.id
            }
          }"
        >
          <div class="d-flex flex-row btn btn-md btn-primary font-weight-bolder text-uppercase rounded align-items-center">
            <span class="">{{$t("PARTIALSPROCESSI.VISUALIZZA")}}</span>
          </div>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ModuleCard",
  props: ["module", "phase"],
  data() {
    return {
      publishVotes: false,
      modulesWithVotes: []
    }
  },
  mounted(){
    this.setModulesWithVotes(this.phase);
    this.publishVotes = this.module.publishVotes ?? false;
    /*phase.phaseStatus.id == 2  && !phase.open && phase.processVotes.length > 0*/
  },
  computed: {
    getModuleIcon() {
      // console.log("icona modulo: ", this.module.logo)
      return this.module.logo
        ? this.module.logo.uri
        : "./assets/media/bipart/ico-municipality.png";
    },
    getModuleCall() {
      let moduleName = "";
      switch (this.module.module.id) {
        case 20: {
          moduleName = "processModuleIdeaList";
          break;
        }
        default: {
          moduleName = "processModuleInProgress";
          break;
        }
      }
      // console.log('moduleName = ' + moduleName);
      return moduleName;
    },
    ...mapGetters([
      "currentUser"
    ]),
  },
  methods: {
    alertNotActive(e){
      e.preventDefault();
      Swal.fire({
        title: this.$t("PARTIALSPROCESSI.ALERT2TITLE"),
        text: this.$t("PARTIALSPROCESSI.ALERT2"),
        icon: "warning",
        confirmButtonClass: "btn btn-secondary"
      });
    },
    setModulesWithVotes (phase) {
      for ( let i = 0; i < phase.processVotes.length; i++) {
        if ( phase.processVotes[i].open && ! this.modulesWithVotes.includes(phase.processVotes[i].process2ModuleId)) {
          this.modulesWithVotes.push(phase.processVotes[i].process2ModuleId);
        }
      }
    }
  }
};
</script>

<style scoped></style>
