<template>
  <div class="row h-100">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 bg-white">
      <div class="pl-4">
        <Phaseline
            :phases="process.processPhases"
            :processId="process.id"
            :processLogo="getProcessLogo">
        </Phaseline>
      </div>
    </div>
    <div class="col-lg-9 col-xl-9 justify-content-start">
      <div class="d-flex flex-column px-6 mt-2">
        <!--::Process Info -->
        <div class="row">
          <div class="col-xl-12">
            <div class="mt-6 card shadow-sm border-0 justify-content-center bgi-size-cover bgi-no-repeat" :style="{'background-image': 'url(' + header_background + ')'}">
              <!--begin::header-->
              <div
                class="card-header bg-transparent border-0 d-flex justify-content-between pt-5 pb-2"
              >
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <span
                      v-if="!process.publiclyVisible"
                      class="label label-inline label-xl mr-2"
                      v-bind:class="{
                        // 'bg-primary': process.publiclyVisible,
                        'bg-danger': !process.publiclyVisible
                      }"
                    >
                      <i class="fas fa-lock text-white"></i>
<!--                      <i class="fas fa-lock-open text-white" v-else></i>-->
                    </span>

                    <span :class="['label label-inline label-xl font-weight-bold py-5 mr-2', this.getStatus.color]">
                      {{ this.getStatus.label }}
                    </span>

                    <!--
                    <span class="font-weight-bolder h5">
                      {{ process.startDate | moment("DD/MM/Y") }}
                    </span>

                    <span v-if="process.endDate" class="label label-inline label-light-danger label-xl font-weight-bold py-5">
                      {{ process.endDate | moment("DD/MM/Y") }}
                    </span>
                    -->

                    <span class="ml-3" v-if="process.startDate"> {{$t('GENERALLABEL.DAL')  | capitalize}} <b class="font-weight-bolder h5">{{ process.startDate | moment("DD/MM/Y")}}</b> </span>
                    <span class="ml-4" v-if="process.endDate"> {{$t('GENERALLABEL.AL')}} <b  class="font-weight-bolder h5">{{ process.endDate | moment("DD/MM/Y")}}</b></span>
                  </div>
                </div>
              </div>
              <!--end::header-->
              <!--begin::Body-->
              <div class="card-body pt-2 pb-5">
                <div class="row w-100">
                  <div
                    v-if="process.id"
                    class="col-sm-12"
                  >
                    <h1
                      class="text-primary font-weight-bolder mb-3"
                    >
                      <router-link
                        :to="{
                          name: 'processDetail',
                          params: { id: process.id }
                        }"
                        class="text-primary"
                      >
                        {{ process.localizations[0].title }}
                      </router-link>
                    </h1>
                    <div
                      class="mt-5 font-weight-normal font-size-h6"
                      v-html="
                        process ? process.localizations[0].description : ''
                      "
                    ></div>
                    <h3 class="mt-3">
                      <span v-if="process.budgetMin" class="">{{$t("PROCESSDETAIL.BUDGETPROPOSTO")}} <b>{{ process.budgetMin }} €</b></span>
                      <span v-if="process.budgetMax" class="">&nbsp;|&nbsp;{{$t("PROCESSDETAIL.BUDGETDISPONIBILE")}} <b>{{ process.budgetMax }} €</b>&nbsp;</span>
                      <i v-if="process.budgetNotes" class="fas fa-info-circle ml-3 text-dark" id="pop-budget"></i>
                    </h3>
                     <b-popover v-if="process.budgetNotes" target="pop-budget" triggers="hover">
                      <template v-slot:title></template>
                      <p>{{ process.budgetNotes }}</p>
                    </b-popover>
                  </div>
                </div>
                <div class="d-flex mb-1 align-items-center">
                  <span class="d-block font-weight-bold mr-5">{{$t("PROCESSDETAIL.PROGRESS")}}</span>
                  <div class="d-flex flex-row-fluid align-items-center">
                    <div class="progress progress-xs mt-2 mb-2 w-100">
                      <div
                        class="progress-bar bg-warning"
                        role="progressbar"
                        :style="'width: ' + percentage + '%;'"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <span class="ml-3 font-weight-bolder"
                      >{{ percentage }}%</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <ProcessMenuMobile :process_id="id"
                             :is_process_faq="isFaq"
                             :is_process_guidelines="isGuidelines"
                             :is_process_about="isAbout"
                             :is_process_narration = "isNarration"
          ></ProcessMenuMobile>
        </div>

        <div class="row px-6 mt-5">
          <b-tabs class="w-100">
            <b-tab active>
              <template v-slot:title>
                <div
                  class="p-3 border-bottom border-2 border-bottom-primary h5"
                >
                  {{$t("PROCESSDETAIL.FASIPROCESSO")}}
                </div>
              </template>
              <b-card-text>
                <div class="row">
                  <div class="col-xl-12 justify-content-start">
                    <div class="d-flex flex-column">
                      <!--::Phases -->
                      <div v-if="process.processPhases" class="row">
                        <div
                          v-for="(item, index) in process.processPhases"
                          :key="item.id"
                          class="col-12 col-lg-12 col-xl-12"
                        >
                          <PhaseCard :phase="item" :index="index"></PhaseCard>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>

          </b-tabs>
        </div>
      </div>
    </div>
    <div
      class="d-none d-lg-block col-lg-1 col-xl-1 justify-content-between align-items-center h-100"
    >
      <div class="d-flex flex-column mt-10 mr-5">

        <!-- <div id="kt_quick_panel_toggle_desktop" class="d-flex">
          <router-link
              v-slot="{ href, navigate }"
              :to="{

            }"
          >
            <a
                :href="href"
                class="mb-5 btn btn-icon bg-warning mx-auto btn-lg px-2"
                @click="navigate"
                id="pop-vote"
            >
              <span class="symbol symbol-rounded">
                <span class="symbol-label font-size-h3 font-weight-bold bg-warning text-white">
                  <i class="fas fa-star text-white pr-0 pb-1"></i>
                </span>
              </span>
            </a>
          </router-link>
          <b-popover target="pop-vote" triggers="hover">
            <template v-slot:title>{{$t("PROCESSDETAIL.ITUOIVOTI")}}</template>
          </b-popover>
        </div> -->
        <template v-if="childDataLoaded">
          <!-- <KTQuickPanelManual
              :process="process"
          ></KTQuickPanelManual> -->
        </template>

        <ProcessMenu :process_id="id"
                     :is_process_faq="isFaq"
                     :is_process_guidelines="isGuidelines"
                     :is_process_about="isAbout"
                     :is_process_narration = "isNarration"></ProcessMenu>
        <!-- <KTQuickPanelManual :process="process"></KTQuickPanelManual>
        <KTQuickPanelManualDesktop :process="process"></KTQuickPanelManualDesktop> -->

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_CURRENT_PROCESS } from "@/core/services/store/processes.module";
import Phaseline from "@/view/layout/common/Phaseline";
import ProcessMenu from "@/view/layout/common/ProcessMenu";
import ProcessMenuMobile from "@/view/layout/common/ProcessMenuMobile";
import PhaseCard from "@/view/pages/process/partials/PhaseCard";
//import KTQuickPanelManual from "@/view/layout/extras/offcanvas/QuickPanelManual.vue";
//import KTQuickPanelManualDesktop from "@/view/layout/extras/offcanvas/QuickPanelManualDesktop.vue";
//import Preloader from "@/view/content/Preloader";

import ApiService from "@/core/services/api.service";
import { processMixin } from "@/mixins/process";

export default {
  name: "process-detail",
  mixins: [processMixin],
  components: {
    PhaseCard,
    Phaseline,
    ProcessMenu,
    ProcessMenuMobile,
    //KTQuickPanelManual,
    //KTQuickPanelManualDesktop,
    //Preloader
  },
  data() {
    return {
      childDataLoaded: false,
      process: [],
      id: this.$route.params.id,
      isFaq: null,
      isGuidelines: null,
      isAbout: null,
      isNarration: null,
      percentage: 0,
      preloaderActive: false
      // backgroundImage: this.process.logo.uri //'assets/media/bipart/header.png' //
      // props: ["process", "index", "percentage"],
    };
  },
  computed: {
    getStatus() {
      let d0 = +moment(new Date()).format("YYYYMMDD");
      let d1 = +moment(this.process.startDate).format("YYYYMMDD");
      let d2 = +moment(this.process.endDate).format("YYYYMMDD");

      if (typeof d2 === 'number' && d0 > d2) {
        return {
          label: this.$t("PROCESSDETAIL.CONCLUSA"),
          color: "label-light-danger"
        };
      } else if (typeof d1 === 'number' && d0 < d1) {
        return {
          label: this.$t("PROCESSDETAIL.DAATTIVARE"),
          color: "label-light-warning"
        };
      } else {
        return {
          label: this.$t("PROCESSDETAIL.INCORSO"),
          color: "label-light-primary"
        };
      }
    },

    header_background() {
      let bg_img = '/assets/media/ripartire/header-process.jpg';
      if(window.location.host.includes('darsenaravenna.bipart.it') || window.location.host.includes('stage.comunediravenna.bipart.it'))
        bg_img = '/assets/media/ripartire/header-process-dare.png';
      if(window.location.host.includes('spendiamolinsieme.bipart.it') || window.location.host.includes('stage.spendiamolinsieme.bipart.it'))
        bg_img = '/assets/media/spendiamolinsieme/header-process-si.png';
      return bg_img;

      // return (window.location.host.includes('darsenaravenna.bipart.it') || window.location.host.includes('stage.comunediravenna.bipart.it')) ? '/assets/media/ripartire/header-process-dare.png' : '/assets/media/ripartire/header-process.jpg';
    }
  },
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
    //   { title: this.process }
    // ]);

  },

  created() {
    this.process = this.getProcess(this.id);
  },

  methods: {
    isTrue(prop) {
      return prop && prop != "";
    },

    getProcess(process_id) {
      this.$isLoading(true);
      let found = false;
      //window.localStorage.removeItem("current_process_data");
      let current_process = window.localStorage.getItem("current_process_data");

      if( current_process != 'undefined' && current_process !== null ) {
        let cod = JSON.parse(current_process);

        this.isFaq = this.isTrue(cod.data.faq);
        this.isGuidelines = this.isTrue(cod.data.guidelines);
        this.isAbout = this.isTrue(this.process.about);
        this.isNarration = this.isTrue(this.process.narration);

        let duration_time = moment(moment()).diff(cod.time);
        if(
          cod.process_id == process_id
          && duration_time < this.$maxDurationTimeProcess
          ) {
          // this.$store.dispatch(SET_CURRENT_PROCESS, cod.data);
          console.log('Processo from LS nel modulo: ', cod.data);
          console.log('Duration: ', duration_time );
          this.percentage = this.getPercentage(cod.data);
          this.childDataLoaded = true;
          found = true;
          this.$isLoading(false);
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
            { title: cod.data.title }
          ]);
          return cod.data;
        }
      }

      if(!found) {
        return new Promise((resolve, reject) => {
          var endpoint = this.generateUrl("processes-expanded-no-groups/" + process_id, true);
          //generateUrl is done
          ApiService.get(this.$apiResource, endpoint)
            .then(res => {
              console.log("Processo by API nel componente: ", res.data);
              this.process = res.data;

              this.isFaq = this.isTrue(res.data.faq);
              this.isGuidelines = this.isTrue(res.data.guidelines);
              this.isAbout = this.isTrue(this.process.about);
              this.isNarration = this.isTrue(this.process.narration);

              this.$store.dispatch(SET_CURRENT_PROCESS, this.process);
              this.percentage = this.getPercentage(this.process);
              this.childDataLoaded = true;
              this.$isLoading(false);
              this.$store.dispatch(SET_BREADCRUMB, [
                { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
                { title: res.data.title }
              ]);
              resolve(res.data);
            })
            .catch(() => {
              this.$isLoading(false);
              reject(0);
            });
        });
      }
    },

    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
    }
  }
};
</script>

<style scoped></style>
